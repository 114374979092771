export const checkRequiredValidationWithMinMax = (
  text,
  field,
  min,
  max,
  required = true
) => {
  let error = '';
  let indianFormat = [6, 7, 8, 9]
  let firstNum = text.split("")[0]
  if (required === true) {
    if (text === '') {
      return (error = `Please enter your ${field}`);
    }
  }
  if (text.length < min) {
    error = `Please enter a valid  ${field}`;
  }
  if (text.length > max) {
    error = `Please enter a valid  ${field}`;
  }
  // console.log(firstNum, "<===========");
  if (field === "phone number" && !indianFormat.includes(Number(firstNum))) {
    console.log("object");
    error = `Please enter a valid Phone number`;
  }
  if (field === "description") {
    if (text.length < min) {
      error = `Please enter a valid ${field} more than ${min} characters`;
    }
    if (text.length > max) {
      error = `Please enter a valid ${field} less than ${max} characters`;
    }
  }
  return error;
};

export const validURL = (str) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  let error = '';
  const isValid = !!pattern.test(str);
  if (!isValid) {
    error = 'Please Select a valid URL';
  }

  return error;
};
export const checkEmailValidation = (emailText, name, required = true) => {
  let error = '';

  if (required === true) {
    if (emailText === '') {
      return (error = `Please enter your ${name}`);
    }
  }

  const pattern =
    /^[a-z0-9_]+(\.[a-z0-9_]+)*@[a-z0-9]+([a-z0-9]+)*(\.[a-z0-9]+([a-z0-9]+)*)*\.[a-z]{2,4}$/;
  if (pattern.test(emailText)) {
    return '';
  } else {
    error = 'Please enter a valid email';
  }
  return error;
};

export const checkMultipleChekboxSelectionWithMinMax = (
  array,
  min = 0,
  max = 10000000
) => {
  let ids = [];
  array.forEach((element, index) => {
    if (element === true) {
      ids.push(index);
    }
  });
  let response = {};
  response.error = '';
  response.data = ids;
  if (ids.length < min) {
    response.error = 'Minimum ' + min + ' item selections is required';
  }

  if (ids.length > max) {
    response.error = 'Maximum item selections is ' + min;
  }
  return response;
};

export const checkEmptyValidation = (field, text) => {
  let error = '';
  if (field === '' || field === undefined || field === null) {
    error = `Please enter your${text ? text : 'answer'}`;
  }
  return error;
};

export const isUrlValid = (emailText, name, required = true) => {
  let error = '';
  if (required === true) {
    if (emailText === '') {
      return (error = `${name} field is required`);
    }
  }
  const pattern =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
  if (pattern.test(emailText)) {
    return '';
  } else {
    error = 'Enter a valid Url: ' + emailText;
  }
  return error;
};

export const checkMobileNumberValidation = (
  field,
  text,
  min,
  max,
  minNumber = false
) => {
  let error = '';
  if (field === '' || field === undefined || field === null) {
    return (error = `Please enter your ${text}`);
  }
  let field1 = parseInt(field);
  if (!Number.isInteger(field1)) {
    return (error = `${text} must be a number`);
  }
  if (field.length !== 10) {
    return (error = `${text} must be ` + 10 + ' digit');
  }
  // if (field.length > max) {
  //   return (error = `${text} field must be less then ` + max + " digit");
  // }
  // if (minNumber && field <= 0) {
  //   return (error = `${text} field must be greater then 1`);
  // }
  return error;
};

export const checkDropdownValidation = (field, text) => {
  let error = '';
  if (field === '' || field === undefined || field === null) {
    error = `Please select ${text}`;
  }
  return error;
};

export const checkPositiveNumberValidator = (value, v1, v2) => {
  if (
    (!value.startsWith('-') && value > 0 && value >= v1 && value <= v2) ||
    !value
  ) {
    return true;
  }
  return false;
};

export const isValidUrl = urlString => {
  let url;
  try {
    url = new URL(urlString);
  }
  catch (e) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}


export const getConvertedColor = (hex, alpha = null) => {
  // Remove the hash at the start if it's there
  hex = hex.replace("#", "");

  // Convert 3-character hex to 6-character hex
  if (hex.length === 3) {
    hex = hex.split("").map((char) => char + char).join("");
  }

  // Extract the red, green, and blue components
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Handle alpha if provided
  if (alpha) {
    let alphaValue = parseFloat(alpha);
    if (alpha.includes("%")) {
      alphaValue = parseInt(alpha) / 100;
    }
    alphaValue = Math.max(0, Math.min(1, alphaValue)); // Clamp between 0 and 1
    return `rgba(${r}, ${g}, ${b}, ${alphaValue})`;
  }

  // Return RGB if no alpha provided
  return `rgb(${r}, ${g}, ${b})`;
}

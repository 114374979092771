//homepageaction
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  Add_User_Info,
  Add_User_Timeline_Upload,
  config,
  Get_Cities,
  Get_Filtered_Vehicle,
  Get_Waiting_Period,
  getTopTenCompanies,
  get_vehicle_range,
  Get_Filtered_Company,
  Get_States,
  Get_Cities_General,
  Get_Nearby_Cities,
  Header_searches,
  Get_HomePage_Banner_Image,
  Get_Popular_Images,
  Get_Nearby_By_Coordinates,
  Get_City_By_Coordinates,
  Get_Popular_Vehicle,
  Get_Popular_Vehicle_Compare
} from '../api';
import {
  APPLY_FILTER,
  GET_CITIES,
  GET_FILTERED_VEHICLES,
  GET_WAITING_PERIOD,
  GET_TOP_TEN_COMPANIES,
  CLEAR_FILTER,
  CITY_SELECT,
  STATE_SELECT,
  VEHICLE_SELECT,
  GET_FILTERED_COMPANIES,
  GET_DEALER_CITIES,
  GET_STATES,
  GET_HOMEPAGE_BANNER_IMAGE,
  POPULAR_IMAGES,
  FILTER_VALUES,
  GET_COMPARE_VEHICLE
} from './types';

export const getCities = (data = {}) => async (dispatch) => {

  const { page = 1, limit = 10, search, state } = data;
  
  console.log(page, limit, search, state, "AAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
  let apiUrl = `${Get_Cities}`;
  const hasQuery = apiUrl.includes('?');
  apiUrl += hasQuery ? '' : '?';
  apiUrl += page ? `page=${page}&` : '';
  apiUrl += limit ? `limit=${limit}&` : '';
  apiUrl += search ? `search=${encodeURIComponent(search)}&` : '';
  apiUrl += state ? `state=${state}&` : ''; 

  apiUrl = apiUrl.endsWith('&') ? apiUrl.slice(0, -1) : apiUrl;

  try {
    const res = await axios.get(apiUrl);
    if (res) {
      dispatch({
        type: GET_CITIES,
        payload: res.data.result.docs,
      });
      return res.data.result.docs;
    }
  } catch (err) {
    console.error('Error fetching cities:', err);
    throw err;
  }
};
export const getCitiesCopy = () => async (dispatch) => {
  try {
    const res = await axios.get(Get_Cities);
    if (res) {
      dispatch({
        type: GET_DEALER_CITIES,
        payload: res.data.result,
      });
      return res
    }
  } catch (err) {
    return err;
  }
};
export const getCitiesGeneral = (data) => async (dispatch) => {
  try {
    const res = await axios.get(Get_Cities_General + data);

    if (res) {
      dispatch({
        type: GET_DEALER_CITIES,
        payload: res.data.result.docs
      })
    }
    return res
  } catch (err) {
    return err;
  }
};
export const getStates = (data) => async (dispatch) => {
  try {
    const res = await axios.get(Get_States + data);
    if (res) {
      dispatch({
        type: GET_STATES,
        payload: res.data.result,
      });
      return res
    }
  } catch (err) {
    return err;
  }
}
export const getWaitingPeriod = () => async (dispatch) => {
  try {
    const res = await axios.get(Get_Waiting_Period);
    if (res) {
      dispatch({
        type: GET_WAITING_PERIOD,
        payload: res.data.result,
      });
    }
  } catch (err) {
    return err;
  }
};

export const getFilteredVehicles = (filterDataObj) => async (dispatch) => {
  try {
    const res = await axios.get(Get_Filtered_Vehicle, {
      params: filterDataObj,
    });
    if (res) {
      dispatch({
        type: GET_FILTERED_VEHICLES,
        payload: res.data.result,
      });
      return res;
    }
  } catch (err) {
    return err;
  }
};

export const getPopularVehicles = (filterDataObj) => async (dispatch) => {
  try {
    const res = await axios.get(Get_Filtered_Vehicle, {
      params: filterDataObj,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const popularVehicles = () => async (dispatch) => {
  try {
    const res = await axios.get(Get_Popular_Vehicle);
    return res;
  } catch (err) {
    return err;
  }
};
export const compareVehiclesSection = (type) => async (dispatch) => {
  try {
    const res = await axios.get(Get_Popular_Vehicle_Compare + `?type=${type}`)
    if (res) {
      dispatch({
        type: GET_COMPARE_VEHICLE,
        payload: res.data.result,
      });
      return res;
    }
  } catch (err) {
    return err;
  }
};
export const getSponseredVehicles = (filterDataObj) => async (dispatch) => {
  try {
    const res = await axios.get(Get_Filtered_Vehicle, {
      params: filterDataObj,
    });
    return res;
  } catch (err) {
    return err;
  }
};
export const getFilteredCompanies = (filterDataObj) => async (dispatch) => {
  try {
    const res = await axios.get(Get_Filtered_Company, {
      params: filterDataObj,
    });
    if (res) {
      dispatch({
        type: GET_FILTERED_COMPANIES,
        payload: res.data.result,
      });
      return res;
    }
  } catch (err) {
    return err;
  }
};

export const addUserInfo = (userData) => async (dispatch) => {
  const body = JSON.stringify(userData);
  localStorage.setItem('userInfo', body);
  localStorage.setItem('userLoaded', true);
  try {
    return await axios.post(Add_User_Info, body, config);
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error('Something went wrong, Please try again later!');
    }
    return error;
  }
};

export const addUserTimelineData = (timelineData) => async (dispatch) => {
  try {
    const res = await axios.post(Add_User_Timeline_Upload, timelineData);
    toast.success(res.data.message);
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error('Something went wrong, Please try again later!');
    }
    return error;
  }
};

export const applyFilter = (status) => async (dispatch) => {
  dispatch({
    type: APPLY_FILTER,
    payload: status,
  });

};

export const gettoptenCompanies = () => async (dispatch) => {
  const res = await axios.get(getTopTenCompanies);
  dispatch({
    type: GET_TOP_TEN_COMPANIES,
    payload: res.data.result
  })
  return res;
}

export const clearFilter = () => async (dispatch) => {
  dispatch({
    type: CLEAR_FILTER,
    payload: []
  })
}

export const cityselect = (data) => async (dispatch) => {
  dispatch({
    type: CITY_SELECT,
    payload: data
  })
}

export const stateselect = (data) => async (dispatch) => {
  dispatch({
    type: STATE_SELECT,
    payload: data
  })
}

export const getVehicleRange = () => async (dispatch) => {
  try {
    const res = await axios.get(get_vehicle_range);
    dispatch({
      type: "GET_VEHICLE_RANGE",
      payload: res.data.result
    })
    return res;
  } catch (err) {
    return err;
  }
}

export const selectVehicle = () => async (dispatch) => {
  dispatch({
    type: VEHICLE_SELECT,
    payload: true
  })
}

export const getNearbyCities = (id) => async (dispatch) => {
  try {
    const res = await axios.get(Get_Nearby_Cities + id)
    if (res) {
      return res
    }
  } catch (err) {
    return err
  }
}

export const getNearByCoordinates = (lat, lng) => async () => {
  try {
    const res = await axios.get(Get_Nearby_By_Coordinates + '?lat=' + lat + '&lng=' + lng)
    if (res) {
      return res
    }
  } catch (err) {
    return err
  }
}

export const getCitybyCoordinates = (lat, lng) => async () => {
  try {
    const res = await axios.get(Get_City_By_Coordinates + '?lat=' + lat + '&lng=' + lng)
    if (res) {
      return res
    }
  } catch (err) {
    return err
  }
}

export const addNearbyCities = (limit, skip) => async (dispatch) => {
  try {
    const res = await axios.post(Add_Nearby_Cities + "?limit=" + limit + "&skip=" + skip)
    if (res) {
      return res
    }
  } catch (err) {
    return err
  }
}

export const saveHeaderSearch = (obj) => async (dispatch) => {
  try {
    const res = await axios.post(Header_searches, obj)
    if (res) return res
  } catch (err) {
    return err
  }
}

export const getBackgroundBannerImage = () => async (dispatch) => {
  try {
    const res = await axios.get(Get_HomePage_Banner_Image);
    dispatch({
      type: GET_HOMEPAGE_BANNER_IMAGE,
      payload: res.data.result
    })
    return res
  } catch (err) {
    return err
  }
}

export const getpopularImages = () => async (dispatch) => {
  try {
    const res = await axios.get(Get_Popular_Images);
    dispatch({
      type: POPULAR_IMAGES,
      payload: res.data.result
    })
    return res
  } catch (err) {
    return err
  }
}

export const AddFilterData = (payload) => async (dispatch) => {
  // console.log(payload)
  dispatch({
    type: FILTER_VALUES,
    payload
  })
}
